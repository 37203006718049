export const TRAINING_PLAN_PROFILE_PERCENTAGE_ENDURANCE = 'endurance';
export const TRAINING_PLAN_PROFILE_PERCENTAGE_STRENGTH = 'strength';
export const TRAINING_PLAN_PROFILE_PERCENTAGE_AGILITY = 'agility';
export const TRAINING_PLAN_PROFILE_PERCENTAGE_REHABILITATION = 'rehabilitation';
export const TRAINING_PLAN_PROFILE_PERCENTAGE_STABILIZING = 'stabilizing';

export const TRAINING_PLAN_PROFILE_PERCENTAGES = [
    {
        key: TRAINING_PLAN_PROFILE_PERCENTAGE_ENDURANCE,
        label: 'Wytrzymałościowe',
    },
    {
        key: TRAINING_PLAN_PROFILE_PERCENTAGE_STRENGTH,
        label: 'Siłowe',
    },
    {
        key: TRAINING_PLAN_PROFILE_PERCENTAGE_AGILITY,
        label: 'Sprawnościowe',
    },
    {
        key: TRAINING_PLAN_PROFILE_PERCENTAGE_REHABILITATION,
        label: 'Rehabilitacyjne',
    },
    {
        key: TRAINING_PLAN_PROFILE_PERCENTAGE_STABILIZING,
        label: 'Stabilizujące',
    },
]