import {
    TRAINING_PLAN_PROFILE_PERCENTAGES,
} from 'Consts/tpProfile';

export const getPercentageLabel = percentage => {
    if(!percentage) {
        return { found: false, label: 'Nieznany' };
    }

    const option = TRAINING_PLAN_PROFILE_PERCENTAGES.find(profilePercentage => profilePercentage.key === percentage);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getBmiProfile = bmi => {
    if (bmi < 16) {
        return 'Wygłodzenie';
    } else if (bmi >= 16 && bmi < 17) {
        return 'Wychudzenie';
    } else if (bmi >= 17 && bmi < 18.5) {
        return 'Niedowaga';
    } else if (bmi >= 18.5 && bmi < 25) {
        return 'Wartość prawidłowa';
    } else if (bmi >= 25 && bmi < 30) {
        return 'Nadwaga';
    } else if (bmi >= 30 && bmi < 35) {
        return 'I stopień otyłości';
    } else if (bmi >= 35 && bmi < 40) {
        return 'II stopień otyłości';
    } else if (bmi >= 40) {
        return 'Otyłość skrajna';
    }
}