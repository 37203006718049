import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { 
    API_RESOURCE_USERS, 
} from 'Consts/apiResources';
import {
    USER_ROLE_ADEPT,
} from 'Consts/userRoles';
import { 
    TRAINING_PLAN_PROFILE_PERCENTAGES
} from 'Consts/tpProfile';

import { fromSelectObject } from 'Utils/object';
import { getFullName } from 'Utils/user';
import { getPercentageLabel } from 'Utils/tpProfile';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdminTrainingPlanProfileEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    getPercentagesFromData = (percentages) => {
        let result = {};

        if (percentages) {
            Object.keys(percentages).map(index => {
                result = {
                    ...result,
                    [index]: percentages[index],
                }
            })
        }

        return result;
    }

    dataToFormState = data => {
        return {
            ...data,
            userId: data.user
                ? { value: data.user.id, label: getFullName(data.user).label }
                : null,
            ...this.getPercentagesFromData(data?.percentages),
        };
    }

    formStateToData = formState => {
        return {
            userId: fromSelectObject(formState.userId),
            percentages: {
                agility: Number(formState.agility),
                endurance: Number(formState.endurance),
                rehabilitation: Number(formState.rehabilitation),
                stabilizing: Number(formState.stabilizing),
                strength: Number(formState.strength),
            }
        };
    }

    onSubmit = formState => {
        return this.onUpdate(formState)
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            id: data.id,
            ...this.formStateToData(formState),
        }).catch(error => {
            Object.keys(error.payload.validationErrors).map(fieldName => {
                error.payload.validationErrors[fieldName].map(errorMessage => {
                    toast.error(`${errorMessage}`);
                });
            });

            if (Object.keys(error.payload.validationErrors).length == 0) {
                toast.error('Wystąpił błąd, spróbuj ponownie później');
            }

            throw error;
        });
    }

    renderPercentagesInputs = () => {
        const { data } = this.props;

        let inputs = [];

        if (data && data.percentages) {
            Object.keys(data.percentages).map(index => {
                inputs.push({
                    type: 'input',
                    name: index,
                    label: getPercentageLabel(index).label,
                    value: '12312',
                    inputProps: {
                        type: 'number',
                    },
                });
            })
        }

        return inputs;
    }

    render() {
        const { location, history, actions, data } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-trainig-plan-profile-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'Nazwa profilu',
                        value: data?.name,
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'userId',
                            label: 'Adept',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    role: USER_ROLE_ADEPT
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        userId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                            required: true,
                        }, ...this.renderPercentagesInputs()],
                    }]}
                />
            </StyledComponent>
        );
    }
}